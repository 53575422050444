import { useUserIsLogged } from "@igloo-be-omnipartners/hooks";
import { RouteComponentProps } from "@reach/router";
import { LayoutLogin, PageLoading } from "@royalcanin-be-partner-portal/ui-kit";
import React, { useEffect } from "react";
import { useLocale, useSetLocale } from "../lib/locale";
import { footerLinks } from "../components/Layout";
import { withPage } from "../lib/withPage";
import { parse } from "query-string";
import { isAccessTokenLoginLoading } from "../lib/AccessTokenLogin";
import { navigate } from "gatsby";
import { formatError } from "../components/FormMyCredentials";
import { useIntl } from "react-intl";

interface ILoginError {
  message: string;
  code: string;
}

const Index = ({ location }: RouteComponentProps) => {
  const locale = useLocale();
  const setLocale = useSetLocale();
  const isLogged = useUserIsLogged();
  const tokenLoginLoading = isAccessTokenLoginLoading();
  const { formatMessage } = useIntl();

  const qs = parse(location?.search || "");
  const loginErrorState: ILoginError | undefined = (location?.state as any)
    ?.loginError as ILoginError;
  const loginErrorCode: string | undefined =
    loginErrorState?.code || (qs?.loginError as string) || "";
  const loginErrorMessage: string | undefined =
    loginErrorState?.message || (qs?.loginError as string) || "";

  useEffect(() => {
    if (isLogged) {
      navigate("/information");
    }
  }, [isLogged]);

  if (tokenLoginLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutLogin
      links={footerLinks.map((footerLink) => ({
        ...footerLink,
        href: footerLink.link[locale],
      }))}
      onLocaleChange={setLocale}
      locale={locale}
      loginError={
        loginErrorMessage
          ? formatError(
              { code: loginErrorCode, message: loginErrorMessage },
              { formatMessage, hideCode: true },
            )
          : undefined
      }
      hideLanguageSelector
    />
  );
};

export default withPage(Index);
